<template>
  <div class="flex" :class="size === 'sm' ? 'h-8' : 'h-11'">
    <Button
      class="!h-auto touch-manipulation rounded-xl rounded-r-none border border-black/12"
      color="secondary"
      :size="size"
      :disabled="disabled"
      data-test-id="quantity-decrease-button"
      @click="quantityBtnClick(quantity - 1)"
    >
      <div class="px-5">
        <span
          v-if="hasMultipleUnitsPerItem"
          class="text-lg text-primary-default"
          >{{ `-${unitsPerItem}` }}</span
        >
        <DynamicPictogram
          v-else
          name="minus"
          :class="disabled ? 'text-black-56' : 'text-primary-default'"
        />
      </div>
    </Button>
    <div
      class="flex flex-auto items-center justify-center border-y border-black/12 bg-black/6"
      :class="{ 'w-16 text-sm': size === 'sm' }"
      data-test-id="quantity"
    >
      <CartPictogram
        v-if="showCartPictogram"
        :cart-amount="totalUnitsPerQuantity"
      />
      <span v-else class="text-black/87">{{ totalUnitsPerQuantity }}</span>
    </div>
    <Button
      class="increase-qty-btn !h-auto touch-manipulation rounded-xl rounded-l-none border border-black/12"
      color="secondary"
      :size="size"
      :disabled="disableIncrease || disabled || quantity === maxQuantity"
      data-test-id="quantity-increase-button"
      @click="quantityBtnClick(quantity + 1)"
    >
      <div class="px-5">
        <span
          v-if="hasMultipleUnitsPerItem"
          class="text-lg text-primary-default"
          >{{ `+${unitsPerItem}` }}</span
        >
        <DynamicPictogram
          v-else
          name="plus"
          :class="
            disableIncrease || disabled || quantity === maxQuantity
              ? 'text-black-56'
              : 'text-primary-default'
          "
        />
      </div>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { debounce } from '@/helpers/utils';

const props = defineProps({
  /** Quantity */
  qty: {
    type: Object,
    default: () => ({
      value: 1,
    }),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disableIncrease: {
    type: Boolean,
    default: false,
  },
  showCartPictogram: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'base',
  },
  unitsPerItem: {
    type: Number,
    default: 1,
  },
  maxQuantity: {
    type: Number,
    default: null,
  },
});

const quantity = ref(1);

const hasMultipleUnitsPerItem = computed(() => props.unitsPerItem > 1);

// Returns the quantity multiplied by the number of units
const totalUnitsPerQuantity = computed(() => {
  if (props.unitsPerItem <= 1) {
    return quantity.value;
  }
  return quantity.value * props.unitsPerItem;
});

watch(
  () => props.qty,
  (qty) => {
    quantity.value = qty.value;
  },
  { immediate: true, deep: true }
);

watch(
  () => quantity.value,
  (newValue) => {
    debounceInput(newValue);
  }
);

const emit = defineEmits(['input', 'beforeChange']);

const quantityBtnClick = (qty: number) => {
  if (props.maxQuantity && qty > props.maxQuantity) return;
  const newQuantity = qty < 0 ? 0 : qty;
  emit('beforeChange', newQuantity);
  quantity.value = newQuantity;
};

const updateQuantity = (input: number) => {
  if (props.maxQuantity && input > props.maxQuantity) return;
  if (input !== props.qty.value) {
    if (input < 0) {
      emit('input', 1);
    } else {
      emit('input', input);
    }
  }
};

const debounceInput = debounce((input: number) => {
  updateQuantity(input);
}, 800);
</script>
